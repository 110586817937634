import { faCouch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Rent = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="不動産の借り入れ"
        description="株式会社ファーストの不動産借用"
      />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faCouch} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          不動産の借り入れ
        </h3>
        <section className="section">
          <h4 className="title is-4">納得できるお部屋探し</h4>
          <p>
            お客様の目線・立場でお部屋探しをサポート致します。単身のお客様はもちろん、カップル様、新婚様、ご家族様、幅広い居住形態へのご提案が可能です。
          </p>
          <p className="block">
            インターネット上の他社掲載物件もご紹介できるため、当社では「納得できるお部屋探し」が可能です。
            審査や保証人、費用に関してなど、どのような難しいご条件でも遠慮なくご相談ください。
          </p>

          <div className="columns is-centered">
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-rent.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>物件提案</span>
                  <ul>
                    <li>
                      ご希望条件を元に打ち合わせをさせて頂き、賃貸物件をご提案します
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>物件見学</span>
                  <ul>
                    <li>経験豊富なスタッフが物件をご案内致します</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>契約・引き渡し</span>
                  <ul>
                    <li>賃貸の申し込み手続きを行います</li>
                    <li>
                      管理会社・保証会社の審査後、賃貸借契約を行い、鍵の引き渡しを行います
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <p>
            他の不動産会社で断られてしまった場合でも、まずは一度ご相談ください。ご納得頂けるお部屋に出会えるまで、お付き合い致します。
          </p>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Rent
